<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
				<div class="tableConTable">

					<div>


						<el-card style="margin-top: 5px;">
							<div class="" style="margin-bottom: 10px; display:flex; align-items: center;">
								<div style="margin-right: 50px;">
									<el-radio-group v-model="activeName">
										<el-radio :label="'first'">SKU</el-radio>
										<el-radio :label="'second'">{{$t('i18nn_4531476fa35570f0')}}</el-radio>
									</el-radio-group>
								</div>
								<div>
									<el-switch v-if="'first'==activeName" v-model="filterData.relationFlag" active-text="启用关联SKU"
										inactive-text="不启用关联SKU" active-value="1" inactive-value="0">
									</el-switch>
								</div>

							</div>

							<!-- <div class="" style="margin-bottom: 10px;" v-if="'first'==activeName">
								<el-switch
								  v-model="filterData.relationFlag"
								  active-text="启用关联SKU"
								  inactive-text="不启用关联SKU"
									active-value="1"
									inactive-value="0">
								</el-switch>
							</div> -->

							<el-input ref="sku" type="text" v-model="filterData.goodsSku" maxlength="50" clearable
								:placeholder="$t('i18nn_c764976f063a67f2')" @keyup.enter.native="initData()" style="width: 800px;">
								<template slot="prepend">
									<!-- <i class="el-icon-collection-tag"></i> -->
									<span v-if="'first'==activeName">SKU</span>
									<span v-else-if="'second'==activeName">{{$t('i18nn_4531476fa35570f0')}}</span>
								</template>
								<el-button slot="append" type="primary" icon="el-icon-search"
									@click="initData()">{{$t('1e7246dd6ccc5539')}}</el-button>
							</el-input>
							<el-button type="text" @click="toggleDrawerScanSku()" size="small"
								style="margin-left:10px;">{{$t('i18nn_b42006e76f7ab948')}}</el-button>

						</el-card>

						<div class="scanCon">
							<div class="scanLeft">
								<!-- <div> -->
								<div class="scanList" v-loading="loading" :style="{'height':$store.state.tableMaxHeight6+'px'}">
									<div class="scanItem">
										<el-card shadow="" class="">
											<div slot="header">
												<strong>{{$t('i18nn_098f32f54c38955e')}}</strong>
											</div>
											<div style="margin: 10px 0;">
												<el-alert type="success"
													:description="$t('i18nn_bad53577db0da2d3')+' = '+$t('i18nn_2e885d00d595e429')+';'+$t('i18nn_8858352f1fa85f37')"
													:closable="false" show-icon>
												</el-alert>
												<!-- <div class="scanInfoContent" style="display: flex;"> -->
												<!-- <div class="scanInfoLeft"> -->
												<el-table :data="currentData.skuInfos" stripe :border="true" style="width: 100%" size="small">
													<el-table-column type="index" fixed="left" width="50" align="center"
														:label="$t('7b1c2b1adc920d9c')"></el-table-column>
													<!-- SKU  发货件数   已扫件数 -->
													<el-table-column prop="goodsSku" :label="'SKU'" width="200">
													</el-table-column>
													<el-table-column prop="quantity" :label="$t('i18nn_bad53577db0da2d3')">
														<template slot-scope="scope">
															<div class="">
																<strong>
																	{{scope.row.quantity}}
																</strong>
															</div>
														</template>
													</el-table-column>
													<el-table-column prop="isScanTotal" :label="$t('i18nn_2e885d00d595e429')">
														<template slot-scope="scope">
															<div class="red">
																<strong>
																	{{scope.row.isScanTotal}}
																</strong>
															</div>
														</template>
													</el-table-column>
													<el-table-column prop="isScanFlag" :label="$t('i18nn_a2984348f5b674aa')">
														<template slot-scope="scope">
															<div>
																<span v-if="1==scope.row.isScanFlag">
																	<el-tag type="">{{$t('25e03120b83ee4ed')}}</el-tag>
																</span>
																<span v-else>
																	<el-tag type="info">{{$t('i18nn_eb8407fcf599c7e9')}}</el-tag>
																</span>
															</div>
														</template>
													</el-table-column>
												</el-table>
												<div style="margin-bottom: 10px;">
													<el-alert v-if="currentData.errorMsg" :title="$t('i18nn_9b61f70b3098b686')" type="warning"
														:description="currentData.errorMsg" show-icon :closable="false">
													</el-alert>
												</div>
												<div style="margin-bottom: 10px;" v-if="1==currentData.printStatus">
													<el-alert v-if="currentData.error" :title="$t('i18nn_92fce53540b740f8')" type="error"
														:description="$t('i18nn_92fce53540b740f8')+'，code'+currentData.error" show-icon
														:closable="false">
													</el-alert>
												</div>
												<div style="margin-bottom: 10px;">
													<el-descriptions title="" size="" :column="1" border style="margin-bottom: 5px;">
														<el-descriptions-item :label="$t('i18nn_6692e48729688461')">
															<div>
																<span v-if="1==currentData.printStatus">
																	<el-tag type="success">
																		{{currentData.printStatusStr}}
																	</el-tag>
																</span>
																<span v-else>
																	<el-tag type="warning">
																		{{currentData.printStatusStr}}
																	</el-tag>
																</span>
															</div>
														</el-descriptions-item>
														<el-descriptions-item :label="$t('i18nn_13f180f311a15217')">
															<span>{{currentData.printLabel}}</span>
															<span
																v-if="currentData.printLabel && 1==currentData.printStatus && currentData.printLabel>0">
																&nbsp;<el-button type="warning" icon="el-icon-printer"
																	@click="rePrint()">{{$t('i18nn_69b1b60d3a29bb0f')}}</el-button>
															</span>
														</el-descriptions-item>
														<el-descriptions-item :label="$t('i18nn_f84d899216c242b6')">
															<div v-if="1==currentData.printStatus">
																<span v-if="currentData.pdfBase64">
																	<el-tag type="success">{{$t('i18nn_c524cff2488f2ebd')}}</el-tag>
																</span>
																<span v-else>
																	<el-tag type="info">{{$t('i18nn_7a4aff235c9a01dd')}}</el-tag>
																</span>
															</div>
														</el-descriptions-item>
													</el-descriptions>
												</div>
												<!-- </div> -->

												<!-- </div> -->


											</div>


										</el-card>
									</div>
								</div>
							</div>

							<div class="scanRight">
								<el-card shadow="never" class="" style="margin-bottom: 10px;" :body-style="{'padding':'10px'}">
									<div slot="header">
										<strong>{{$t('i18nn_74cb497a2bc3955f')}}</strong>
									</div>
									<div class="scanCodeList">
										<div style="margin-bottom: 10px;">
											<div class="">
												<el-descriptions title="" :size="''" border style="margin-bottom: 5px;">
													<el-descriptions-item :label="$t('ae61193590bd513d')">
														<strong style="color:#4169E1;font-size:18px;">{{currentData.orderNumber}}</strong>
													</el-descriptions-item>
													<el-descriptions-item :label="$t('i18nn_4531476fa35570f0')">
														<strong style="color:#800000;font-size:18px;">{{currentData.tracking}}</strong>
													</el-descriptions-item>
													<el-descriptions-item :label="$t('i18nn_9ccb286534db9538')+'SKU'">
														<strong style="color:#FF4500;font-size:18px;">{{ currentData.goodsSku }}</strong>
													</el-descriptions-item>
												</el-descriptions>
											</div>
											<div style="margin-bottom: 10px;">
												<el-descriptions title="" :size="''" :column="2" border style="margin-bottom: 5px;">
													<el-descriptions-item :label="$t('e44e9a291d80c593')">
														<!-- <span> -->
														{{currentData.name}}
														<!-- </span> -->
													</el-descriptions-item>
													<el-descriptions-item label="EMail">{{currentData.email}}
													</el-descriptions-item>
													<el-descriptions-item :label="$t('c2d9cc88561f8cdc')">
														<!-- <span> -->
														{{currentData.phone}}
														<!-- </span> -->
													</el-descriptions-item>
													<el-descriptions-item :label="$t('c3755bab459a2cbc')">{{currentData.company}}
													</el-descriptions-item>
													<el-descriptions-item :label="$t('i18nn_8db45b615b72b7a8')">
														<!-- <span> -->
														{{currentData.street1}}
														<!-- </span> -->
													</el-descriptions-item>
													<el-descriptions-item :label="$t('i18nn_3e429a7c7cf3cab2')">
														<!-- <span> -->
														{{currentData.street2}}
														<!-- </span> -->
													</el-descriptions-item>
													<el-descriptions-item
														:label="$t('i18nn_b7f95c7fddc0d602')+','+$t('i18nn_7cab79716236ebb0')+','+$t('c4913ab43009b365')">
														<!-- <span style=""> -->
														{{currentData.city}}&nbsp;,&nbsp;{{currentData.state}}&nbsp;,&nbsp;{{currentData.postalCode}}
														<!-- </span> -->
													</el-descriptions-item>
													<el-descriptions-item :label="$t('b166e4e8fe9513fa')">{{currentData.country}}
													</el-descriptions-item>
												</el-descriptions>
											</div>

											<div style="margin-bottom: 10px;">
												<el-descriptions title="" size="" border style="margin-bottom: 5px;">
													<el-descriptions-item :label="$t('i18nn_b458f03c43a90ee9')">
														{{currentData.batchNo}}
													</el-descriptions-item>
													<el-descriptions-item :label="$t('i18nn_66e4e9fd7415a919')">
														{{currentData.printSeq}}
													</el-descriptions-item>
													<el-descriptions-item :label="$t('15b3627faddccb1d')">
														<div class="pre-text red">
															{{currentData.remark}}
														</div>
													</el-descriptions-item>
													<el-descriptions-item :label="$t('i18nn_1324a1d91ae3de53')">
														<a :href="currentData.labelUrl" target="_blank">
															{{currentData.labelUrl}}
														</a>
													</el-descriptions-item>
												</el-descriptions>
											</div>
										</div>
									</div>
								</el-card>
							</div>

							<!-- <div class="scanRight"> -->

						</div>

					</div>
				</div>
			</div>
		</div>

		<el-drawer :wrapperClosable="false" :title="$t('i18nn_b42006e76f7ab948')" append-to-body
			:visible.sync="drawerScanSku" :direction="'rtl'">
			<div>
				<div>
					<el-card shadow="never" class="" style="margin-bottom: 10px;" :body-style="{'padding':'10px'}">
						<div slot="header">
							<span>{{$t('i18nn_4a06d4093d28db10')}}</span>
						</div>
						<ul class="scanCodeList">
							<li v-for="(item,index) in scanList" :key="index">
								<el-tag v-if="index==0" :type="'primary'" effect="plain">
									{{ item.scanCode }}
								</el-tag>
							</li>
						</ul>
					</el-card>
					<el-card shadow="never" class="scanRiCon" :body-style="{'padding':'10px'}"
						:style="{'height':$store.state.tableMaxHeight10+'px'}">
						<div slot="header">
							<span>{{$t('i18nn_2b0a6beffb55e690')}}</span>
						</div>
						<ul class="scanCodeList">
							<li v-for="(item,index) in scanList" :key="index">
								<el-tag v-if="index!=0" :type="'info'" effect="plain">
									{{ item.scanCode }}
								</el-tag>
							</li>
						</ul>
					</el-card>
				</div>
			</div>
		</el-drawer>


	</div>
</template>
<script>
	// import BatchScanPrintDetList from '@/components/WarehouseCenter2/DropShipping/BatchScanPrintDetList.vue';
	import {
		priterPdfBase64,
		// GetPrinterList,
		// savePrtVal,
		// getPrtVal
	} from '@/utils/LodopPrinter.js';
	export default {
		// name: 'HomeConfFrame',
		//meta信息seo用
		// metaInfo: {
		// 	title: 'HYTX-WMS-ADMIN'
		// },
		props: {
			openTime: {},
			// isBatchNoData:{
			// 	default: function() {
			// 	  return false;
			// 	},
			// 	type: Boolean
			// },
			type: {
				default: function() {
					return '';
				},
				type: String
			},
			outLpn: {
				default: function() {
					return '';
				},
				type: String
			},
			batchNo: {
				default: function() {
					return '';
				},
				type: String
			},
			printerVal: {},
			printerSize: {},
			printBatchInfo: {},
		},
		components: {
			// BatchScanPrintDetList
		},
		data() {
			return {

				drawerScanSku: false,

				loading_load: false,

				// tabActiveName: "first",

				activeName: 'first',
				// loading_det_load: false,

				// loading_count: false,
				// countData: {},
				// printBatchInfo: {},
				//表格数据
				//loading,表格数据
				loading: false,
				//表格数据
				// tableData: [],
				currentData: {},

				print_loading: false,

				scanList: [],

				// ScanPrintDetOpenTime:'',
				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 20, 50, 100, 200, 500],
				// 	page_size: 500,
				// 	total: 0
				// },
				// countPdfLabel: 0,
				// skuQuantity: 0,

				// printerVal: "",
				// printerSize: "1",
				// printerList: [],
				// sizeList: [{
				// 		value: '1',
				// 		label: '4in*适应'
				// 	},
				// 	{
				// 		value: '2',
				// 		label: '4in*6in缩小'
				// 	},
				// 	{
				// 		value: '3',
				// 		label: '自适应'
				// 	}
				// ],

				// selectOption: {
				// 	wh_no: [],
				// },
				isShowBatchNoInput: true,
				// isBatchNoData: false,
				//查询，排序方式
				filterData: {
					// orderBy: 'id_', //排序字段
					// sortAsc: 'desc', //desc降序，asc升序

					// batchNo: '',

					goodsSku: '',
					relationFlag: "1", //开启--1，不开启--0

				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.init();
				//初始化
				// this.dialogFile = true;
			}
		},
		// beforeRouteUpdate(to, from, next) {
		// 	console.log('WhDropShipping beforeRouteUpdate', to, from);
		// 	next();
		// },
		activated() {

			// console.log('WhDropShippingBatchScanPrint activated');
			this.init();
		},
		//创建时
		created() {
			// console.log('WhDropShippingBatchScanPrint created');
			// this.init();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.getWhNoListData();
		},
		methods: {
			init() {
				this.clearFilter();
				this.filterData.relationFlag = "1";
				// if (this.$route.query && this.$route.query.batchNo) {
				// 	this.filterData.batchNo = this.$route.query.batchNo;
				// 	this.isShowBatchNoInput = false;
				// 	// this.initData();
				// } else {
				// 	this.isShowBatchNoInput = true;
				// 	this.isBatchNoData = false;
				// }
				// //查询拣货单号信息
				// if (this.filterData.batchNo) {
				// 	this.getPrintInfoByBatch();
				// }

				//自动获取焦点
				// this.$nextTick(() => {
				// 	if (!this.filterData.batchNo) {
				// 		if (this.$refs.batchNo) {
				// 			this.$refs.batchNo.focus();
				// 		}
				// 	} else {
				// 		if (this.$refs.sku) {
				// 			this.$refs.sku.focus();
				// 		}
				// 	}
				// })
				// this.tableData = [];
				this.scanList = [];
				this.currentData = {};
				// this.initPriterList();
				//设置上次打印机的值
				// this.printerVal = getPrtVal();
				this.skuInputFocus();

			},
			skuInputFocus() {
				this.$nextTick(() => {
					if (this.$refs.sku) {
						this.$refs.sku.focus();
					}
				})
			},
			//清空拣货单号
			// clearBatchNo() {
			// 	this.filterData.batchNo = "";
			// 	this.isShowBatchNoInput = true;
			// 	this.isBatchNoData = false;
			// 	this.scanList = [];
			// 	this.currentData = {};
			// 	//自动获取焦点
			// 	this.$nextTick(() => {
			// 		if (this.$refs.batchNo) {
			// 			this.$refs.batchNo.focus();
			// 		}
			// 	})
			// },
			initData() {
				if ('2' == this.type) {
					if (!this.outLpn) {
						this.$message.warning('请输入OLPN');
						return;
					}
				} else {
					if (!this.batchNo) {
						this.$message.warning(this.$t('i18nn_c4189210d2599fd7'));
						return;
					}
				}

				if (!this.filterData.goodsSku) {
					this.$message.warning(this.$t('i18nn_85a02086f987be2b') + "SKU");
					return;
				}
				//保存此次打印机的值
				// savePrtVal(this.printerVal);
				// this.currentData = {};
				//调用接口
				this.getPageData();


			},
			// goBack() {
			// 	// console.log('window.history.length',window.history.length);
			// 	// if (window.history.length > 2) {
			// 		this.$router.go(-1);
			// 	// } else {
			// 	// 	this.$router.push({
			// 	// 		name: 'WhDropShippingBatchDet'
			// 	// 	});
			// 	// }
			// },
			toggleDrawerScanSku() {
				this.drawerScanSku = !this.drawerScanSku;
			},
			clearFilter() {
				Object.getOwnPropertyNames(this.filterData).forEach(key => {
					// console.log(key,obj[key]);
					// if ('orderBy' != key && 'sortAsc' != key) {
					this.filterData[key] = '';
					// }
				});
				// this.$nextTick(() => {
				// 	this.$refs.cusSelFuzzy.clearData();
				// });
			},
			//保存选择的打印机
			// changePrinter(v) {
			// 	//保存此次打印机的值
			// 	savePrtVal(this.printerVal);
			// },

			//初始化打印机列表
			// initPriterList() {
			// 	let printerList = GetPrinterList();
			// 	console.log('printerList', printerList);
			// 	this.printerList = printerList;
			// },

			//查看已打或未打
			// showPrintDet(){
			// 	this.ScanPrintDetOpenTime = new Date().getTime();
			// },

			//分页的筛选项数据
			pageFilterData() {
				let scanType = ''; //1：sku  2：运单号
				if ('second' == this.activeName) {
					scanType = '2';
				} else {
					scanType = '1';
				}
				return {
					outLpn: this.outLpn ? this.outLpn : null,
					batchNo: this.batchNo ? this.batchNo : null,
					"keyword": this.filterData.goodsSku ? this.filterData.goodsSku : null,
					"userId": this.printBatchInfo.userId ? this.printBatchInfo.userId : null,
					"scanBatchPrint": '1', //1-->单条，2-->多条
					"scanType": scanType,
					"relationFlag": this.filterData.relationFlag ? this.filterData.relationFlag : null,
				};
			},
			//
			getPageData() {
				//防止扫码过快
				if (this.loading_load) {
					this.$message.warning("loading...");
					return;
				}
				let filterData = {};
				//如果 0==printStatus，把上次结果回传
				if ('0' === this.currentData.printStatus) {
					filterData = Object.assign({}, this.currentData, this.pageFilterData());
				} else {
					filterData = this.pageFilterData();
				}

				let Print_url = this.$urlConfig.WhDsScanPrint;
				if (filterData.outLpn) {
					Print_url = this.$urlConfig.WhDsScanPrintByOLpn;
				} else if (filterData.batchNo) {
					Print_url = this.$urlConfig.WhDsScanPrint;
				}

				this.currentData = {};
				this.loading_load = true;
				this.$emit('loadingBack',this.loading_load);
				this.$http
					.put(Print_url, filterData)
					.then(async ({
						data
					}) => {


						if (200 == data.code && data.data) {
							let currentData = data.data;
							if (1 == currentData.printStatus && currentData.printLabel && currentData.printLabel >
								0) {
								this.currentData = currentData;
								// this.$message.warning("已打印过此面单，如需重新打印，请点击‘重新打印’按钮");
								this.$alert(
									'<div style="color:red;">' +
									this.$t('i18nn_42e8610163aaeda1') +
									'；<br />' +
									this.$t('i18nn_e395c2d3d12126a5') +
									'，<br />' +
									this.$t('i18nn_5fdb4151c7c11503') +
									'<strong>' +
									this.$t('i18nn_69b1b60d3a29bb0f') +
									'</strong>' +
									this.$t('i18nn_577ba81af19408f2') +
									'</div>',
									this.$t('cc62f4bf31d661e3'), {
										type: 'warning',
										dangerouslyUseHTMLString: true
									});
									this.loading_load = false;
									this.$emit('loadingBack',this.loading_load);
								return;
							}
							//当前数据总条数
							// this.pagination.total = parseInt(data.total);
							//清空SKU并调用打印
							let obj = {};
							// let isRepeat = this.tableData;
							// if(isRepeat){//是否重复
							// 	obj = data.data;
							// 	obj.isRepeat = true;
							// } else {//打印
							obj = await this.autoPrint(currentData);
							// obj.scanSkuCode = filterData.keyword;
							// }
							//表格显示数据
							// this.tableData = this.tableData.concat([obj]);
							this.currentData = obj;

							//更新批次总信息
							this.loading_load = false;
							this.$emit('loadingBack',this.loading_load);
							this.$emit('updateInfo');
							//扫描成功
							this.scanSuccessAction();
						} else {
							this.loading_load = false;
							this.$emit('loadingBack',this.loading_load);
							//扫描成功
							this.scanSuccessAction();
							let errorMsg = data.msg ? data.msg : this.$t('i18nn_33a114a8cba0232f');
							this.$message.warning(errorMsg);
							// this.$notify({
							// 	title: '提示',
							// 	type: 'warning',
							// 	message: errorMsg,
							// 	duration: 10000
							// });
							let obj = {
								// scanSkuCode: filterData.keyword,
								httpError: "1",
								errorMsg: errorMsg
							};
							// this.tableData = this.tableData.concat([obj]);
							this.currentData = obj;
						}
					})
					.catch(error => {
						this.loading_load = false;
						this.$emit('loadingBack',this.loading_load);
						//扫描成功
						this.scanSuccessAction();
						console.log(error);
						let errorMsg = data.msg ? data.msg : '查询失败！';
						this.$message.error(errorMsg);
						// this.$notify({
						// 	title: '提示',
						// 	type: 'error',
						// 	message: errorMsg,
						// 	duration: 0
						// });
						let obj = {
							// scanSkuCode: filterData.keyword,
							httpError: "2",
							errorMsg: errorMsg
						};
						// this.tableData = this.tableData.concat([obj]);
						this.currentData = obj;

					});
			},
			//扫描成功
			scanSuccessAction() {
				this.scanList.unshift({
					scanCode: this.filterData.goodsSku
				});
				this.filterData.goodsSku = "";
				this.$nextTick(() => {
					this.$refs.sku.focus();
				})
			},
			//调用打印
			async autoPrint(data) {
				let item = data;

				let reData = {};
				//扫描完成才可以打印
				if (1 == item.printStatus) {
					item.sku = data.goodsSku;
					item.quantity = data.quantity;
					let base64 = data.pdfBase64;
					let printSeq = data.printSeq;
					let printType = data.printType;
					// reData = await priterPdfBase64('ScanPrint', item, base64, printSeq, null, this.printerVal, this
					// 	.printerSize, false);
					reData = await priterPdfBase64({
						Prt_Init_Name: 'ScanPrint',
						Item_Data: item,
						base64: base64,
						printSeq: printSeq,
						printType: printType,
						Index: null,
						PRINTER_INDEX: this.printerVal,
						PAGESIZE_TYPE: this.printerSize,
						IS_PREVIEW: false
					});
				} else {
					reData = item;
				}
				// this.countPdfLabelAction(item.pdfNum, item.quantity);
				return reData;
			},
			//重新打印
			rePrint() {
				this.autoPrint(this.currentData);
			},
			//拣货单号基本信息
			// getPrintInfoByBatchAction() {
			// 	if (!this.filterData.batchNo) {
			// 		this.$message.warning(this.$t('i18nn_5d756d95aa048878'));
			// 		return;
			// 	}
			// 	this.getPrintInfoByBatch();
			// },
			//查询批次数据
			// getPrintInfoByBatch() {
			// 	//防止扫码过快
			// 	if (this.loading_load) {
			// 		this.$message.warning("正在加载,请稍候");
			// 		return;
			// 	}
			// 	this.isBatchNoData = false;
			// 	let filterData = {
			// 		"batchNo": this.filterData.batchNo
			// 	}
			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhDsScanPrintInfo, filterData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			this.loading_load = false;

			// 			if (200 == data.code && data.data) {
			// 				this.isBatchNoData = true;
			// 				this.printBatchInfo = data.data;
			// 				this.$nextTick(() => {
			// 					this.$refs.sku.focus();
			// 				});

			// 			} else {
			// 				let errorMsg = data.msg ? data.msg : this.$t('i18nn_45ec4789fbc5c60f');
			// 				this.$message.warning(errorMsg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			let errorMsg = data.msg ? data.msg : '查询该批次信息失败！';
			// 			this.$message.error(errorMsg);
			// 		});
			// },
			//计算总面单数
			// countPdfLabelAction(pdfNum, skuQuantity) {
			// 	if (pdfNum) {
			// 		this.countPdfLabel = this.countPdfLabel + parseInt(pdfNum);
			// 	}
			// 	if (skuQuantity) {
			// 		this.skuQuantity = this.skuQuantity + parseInt(skuQuantity);
			// 	}
			// },
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},
			//多选
			handleSelectionChange2(val) {
				console.log(val);
				// this.multipleSelection2 = val;
			},
			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;
				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

			//查询数据字典
			// getDicData() {
			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_carrier_code'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				this.selectOption.wh_carrier_code = data.data['wh_carrier_code'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('hytxs0000032');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('hytxs0000033'));
			// 		});
			// },

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.scanCon {
		display: flex;
		margin-top: 10px;
	}

	.scanLeft {
		width: 600px;
		// width: 1000px;
		// flex:1;
		margin: 0 10px;
	}

	.scanRight {
		// width: 300px;
		min-width: 1000px;
		max-width: 2000px;
		flex: 1;

		.scanRiCon {
			overflow: auto;
		}
	}

	.scanCodeList {
		li {
			margin: 10px 0;
		}
	}

	// .scanCard {
	// 	position: relative;
	// 	.scanTips {
	// 		position: absolute;
	// 		right:0;
	// 		top:0;

	// 	}
	// }

	.scanList {
		// padding: 10px;
		// overflow: auto;

		.scanItem {
			margin-bottom: 10px;

			.scanLine {
				margin: 10px;
				display: flex;

				// justify-content: space-between;
				.scanLineData {
					padding: 0 10px;
					;
				}
			}
		}
	}

	// .descRecLabel {
	// 	// width: 300px;
	// 	// text-align: right;
	// 	// color:red;
	// 	background: #fff;
	// }
	// .scanInfoContent {
	// 	display: flex;
	// 	.scanInfoLeft {

	// 	}
	// 	.scanInfoRight {

	// 	}
	// }
</style>