<template>
	<!-- <el-dialog :title="$t('i18nn_785ee8efb6d09b2a')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="800px"
		top="10px"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_58213be577a36611')" append-to-body :visible.sync="dialogFile"
		:direction="'rtl'" size="600px">
		<div class="">

			<el-tabs type="border-card" v-model="activeName" v-loading="loading_load">
				<el-tab-pane label="" name="first">
					<div slot="label">{{$t('i18nn_db2c3e5fbf7e8716')}}<el-badge :value="tableData.length"
							type="warning"></el-badge>
					</div>

					<el-table ref="multipleTable" :data="tableData" stripe :border="true" :height="$store.state.tableMaxHeight"
						style="width: 100%" size="small">
						<el-table-column type="index" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>
						<el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>
						<el-table-column prop="tracking" :label="$t('i18nn_4531476fa35570f0')"></el-table-column>
						<el-table-column prop="orderNumber" :label="$t('i18nn_abf9f4b8c93d641e')"></el-table-column>
						<el-table-column prop="printLabel" :label="$t('i18nn_8b7f3722921430ec')"></el-table-column>

					</el-table>
				</el-tab-pane>
				<el-tab-pane label="" name="second">
					<div slot="label">{{$t('i18nn_4930700ae08b8ce9')}}<el-badge :value="tableData2.length"
							type="danger"></el-badge>
					</div>
					<el-table ref="multipleTable2" :data="tableData2" stripe :border="true" :height="$store.state.tableMaxHeight"
						style="width: 100%" size="small">
						<el-table-column type="index" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>
						<el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>
						<el-table-column prop="tracking" :label="$t('i18nn_4531476fa35570f0')"></el-table-column>
						<el-table-column prop="orderNumber" :label="$t('i18nn_abf9f4b8c93d641e')"></el-table-column>
						<el-table-column prop="printLabel" :label="$t('i18nn_8b7f3722921430ec')"></el-table-column>
					</el-table>
				</el-tab-pane>

				<el-tab-pane label="" name="third">
					<div slot="label">未打印SKU库位明细<el-badge :value="tableData3.length" type="danger"></el-badge>
					</div>
					<div>
						<el-button size="small" type="primary" icon="el-icon-printer" @click="printNoScanSku()">
							打印
						</el-button>
						<!-- <div style="padding: 5px;">
							<span>拣货单号</span>:
							<strong>{{tableData3_plNo}}</strong>
						</div> -->
						<el-table ref="multipleTable3" :data="tableData3" stripe :border="true"
							:height="$store.state.tableMaxHeight2" style="width: 100%" size="small">
							<el-table-column type="index" fixed="left" width="50" align="center"
								:label="$t('7b1c2b1adc920d9c')"></el-table-column>
							<el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>
							<el-table-column prop="quantity" :label="'数量'"></el-table-column>
							<el-table-column prop="place" :label="'库位'">
								<template slot-scope="scope">
									<span v-if="scope.row.place && scope.row.place.length>0">{{scope.row.place.join(',')}}</span>
								</template>
							</el-table-column>
						</el-table>
					</div>

				</el-tab-pane>
			</el-tabs>

		</div>
		<!-- <div slot="footer" class="dialog-footer">
			<el-button type="primary" plain @click="dialogFile = false">{{ $t('4e9fc68608c60999') }}</el-button>
		</div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	export default {
		// name: 'HomeConfFrame',
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			// row: {},
			outLpn: {},
			batchNo: {}
		},
		components: {

		},
		data() {
			return {
				dialogFile: false,
				// multipleSelection: [],
				// expands:[],

				loading_load: false,

				loading: false,
				//表格数据
				tableData: [],
				tableData2: [],
				tableData3: [],
				// tableData3_plNo:"",

				activeName: 'first',
				// //分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 20, 50, 100, 200, 300],
				// 	page_size: 200,
				// 	total: 0
				// },
				// formData:{
				// 	plCount:10,
				// },

				// selectOption: {
				// 	wh_no: [],
				// },
				//查询，排序方式
				// filterData: {

				// }
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},

		activated() {
			// console.log('WhDropShipping activated');

		},
		//创建时
		created() {
			// console.log('WhDropShipping created');
		},
		//编译挂载前
		mounted() {},
		methods: {
			goBack() {

			},

			initData() {
				this.tableData = [];
				this.tableData2 = [];
				this.tableData3 = [];
				// this.tableData3_plNo = "";
				this.getPageData();
			},

			//请求分页数据
			getPageData() {
				// let _this = this;

				let filterData = {
					batchNo: this.batchNo ? this.batchNo : null,
					outLpn: this.outLpn ? this.outLpn : null,
				};

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhDsScanPrintDet, filterData)
					.then(({
						data
					}) => {
						this.loading_load = false;
						if (200 == data.code && data.data) {
							//表格显示数据
							if (data.data.printed) {
								this.tableData = data.data.printed;
							}
							if (data.data.noPrint) {
								this.tableData2 = data.data.noPrint;
							}
							if (data.data.noPrintGoodsSkuInfo && data.data.noPrintGoodsSkuInfo.goodsSkuInfo) {
								this.tableData3 = data.data.noPrintGoodsSkuInfo.goodsSkuInfo;
								// this.tableData3_plNo =  data.data.noPrintGoodsSkuInfo.relationNo;
							}

							//当前数据总条数
							// this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							// this.$message.success('商家账单列表，请求成功');
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},

			printNoScanSku() {
				let routeData = this.$router.resolve({
					name: 'whPriterDropShippingPickingListNoprt',
					query: {
						batchNo: this.batchNo ? this.batchNo : '',
						outLpn: this.outLpn ? this.outLpn : '',
					}
				});
				window.open(routeData.href, '_blank');
			},

			//选择行
			// handleCurrentChange(row, event, column) {
			// 	// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			// },
			// //多选
			// handleSelectionChange(val) {
			// 	console.log(val);
			// 	// this.multipleSelection = val;
			// },

			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;

				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading = false;
						if (200 == data.code) {
							callback(data);
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},


		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>